import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import config from './config';

const firebaseConfig = {
  //config here i will add
    apiKey: config.apiKey,
    authDomain: config.authDomain,
    projectId: config.projectId,
    storageBucket: config.storageBucket,
    messagingSenderId: config.messageSenderId,
    appId: config.appId,
    measurementId: config.measurementId
};

const app = initializeApp(firebaseConfig);

const auth = getAuth(app);

export { auth };