import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';

function Sidebar({ closeSidebar, activePath, setIsAuthenticated }) {  // Receive setIsAuthenticated as a prop
  const navigate = useNavigate();
  const { t } = useTranslation();
  
  const handleLogout = () => {
    localStorage.removeItem('token');
    setIsAuthenticated(false);  // Explicitly set authentication state to false
    closeSidebar();
    navigate('/');  // Redirect to the login page
  };

  const sidebarItems = [
    { path: "/dashboard", label: t('Dashboard') },
    { path: "/new-scooter", label: t('New Scooter') },
    { path: "/new-rent", label: t('New Rental') },
    { path: "/return-rent", label: t('Return Rental') },
    { path: "/customer-list", label: t('Customer List') },
    { path: "/scooter-list", label: t('Scooter List') },
    { path: "/past-rented", label: t('Past Rentals') },
  ];

  return (
    <div className="w-64 bg-indigo-900 text-white flex flex-col h-full">
      <div className="px-6 py-4 border-b border-indigo-700">
        <h2 className="text-2xl font-bold">{t('Korkiland Dashboard')}</h2>
      </div>
      <nav className="flex-grow px-6 py-4">
        <ul className="space-y-4">
          {sidebarItems.map((item) => (
            <li key={item.path}>
              <Link
                to={item.path}
                className={`block p-2 rounded hover:bg-indigo-700 flex items-center ${
                  activePath === item.path ? "bg-indigo-700" : ""
                }`}
                onClick={closeSidebar}
              >
                {activePath === item.path && (
                  <span className="w-2 h-2 bg-white rounded-full mr-2"></span>
                )}
                {item.label}
              </Link>
            </li>
          ))}
          <li>
            <button
              onClick={handleLogout}  // Simplified logout and sidebar close sequence
              className="block w-full text-left p-2 rounded hover:bg-indigo-700"
            >
              {t('Logout')}
            </button>
          </li>
        </ul>
      </nav>
    </div>
  );
}

export default Sidebar;
