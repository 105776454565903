import React, { useState } from 'react';
import axios from 'axios';
import config from '../config';
import Swal from 'sweetalert2'
import { useTranslation } from 'react-i18next';

const NewScooter = () => {
    const { t } = useTranslation();
  const [plateNumber, setPlateNumber] = useState('AD');
  const [status, setStatus] = useState('available');
  const [condition, setCondition] = useState('');
  const [message, setMessage] = useState('');

  const handlePlateNumberChange = (e) => {
    let value = e.target.value.toUpperCase();

    if (value.length <= 2) {
      value = value.replace(/[^A-Za-z]/g, ''); // Only allow letters for the first two characters
    } else {
      value = value.slice(0, 2) + value.slice(2).replace(/[^0-9]/g, '').slice(0, 4); // Enforce 4 digits after two letters
    }

    setPlateNumber(value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    // Regular expression to check if the plate number follows the pattern: two letters followed by exactly four digits
    const plateNumberRegex = /^[A-Z]{2}\d{4}$/;
  
    if (!plateNumberRegex.test(plateNumber)) {
      // Display an error message if the plate number doesn't match the format
      setMessage('Plate number must consist of two letters followed by exactly four digits (e.g., AD1234).');
      Swal.fire({
        title: "Invalid Plate Number",
        text: "Plate number must consist of two letters followed by exactly four digits (e.g., AD1234).",
        icon: "error",
      });
      return; // Prevent the form submission
    }
  
    const newScooter = {
      plate_number: plateNumber,
      status,
      condition,
    };
  
    try {
      // Make the API request using axios
      const response = await axios.post(`${config.apiBaseUrl}/api/scooters/add`,
      newScooter,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        }
      }
      );
  
      if (response.status === 201) {
        const data = response.data;
  
        // Reset form fields
        setPlateNumber('AD');
        setStatus('available');
        setCondition('');
        setMessage(`New Scooter Number: ${data.scooter} added!`);
  
        // Show success message with the scooter plate number
        Swal.fire({
          title: "Scooter Added!",
          text: `New Scooter Number: ${data.scooter} added!`,
          icon: "success",
        });
      }
    } catch (error) {
      console.error('Error adding scooter:', error);
  
      if (error.response.status === 400) {
        const data = error.response.data;
  
        setMessage(`Scooter with the same plate number ${data.scooter} already exists!`);
        Swal.fire({
          title: "Scooter Failed!",
          text: `Scooter with the same plate number ${data.scooter} already exists!`,
          icon: "error",
        });
      }
      else if (error.response.status === 401) {
        // Unauthorized, remove token and redirect to home
        localStorage.removeItem("token");
      }
       else {
        setMessage(`Failed to add scooter. Please try again.`);
        Swal.fire({
          title: "Error",
          text: "Failed to add scooter. Please try again.",
          icon: "error",
        });
      }
    }
  };
  
  
  return (
    <div>
        <h1 className="text-3xl font-bold mb-6">{t('New Scooter')}</h1>
    <div className="max-w-md mx-auto bg-white shadow-lg rounded-lg p-6">
      <h1 className="text-2xl font-bold text-gray-900 mb-6">Add New Scooter</h1>
      {message && <p className="mb-4 text-center text-indigo-600">{message}</p>}
      <form onSubmit={handleSubmit} className="space-y-4">
        
        {/* Plate Number Input */}
        <div>
          <label className="block text-sm font-medium text-gray-700">Plate Number</label>
          <input
            type="text"
            value={plateNumber}
            onChange={handlePlateNumberChange}
            placeholder="AD1234"
            className="w-full border border-gray-300 rounded-lg py-2 px-3 text-gray-800"
            maxLength="6"
            required
          />
          <p className="text-sm text-gray-500 mt-1">Format: Two letters followed by four numbers (e.g., AD1234).</p>
        </div>

        {/* Status Dropdown */}
        <div>
          <label className="block text-sm font-medium text-gray-700">Status</label>
          <select
            value={status}
            onChange={(e) => setStatus(e.target.value)}
            className="w-full border border-gray-300 rounded-lg py-2 px-3 text-gray-800"
            required
          >
            <option value="available">Available</option>
            <option value="rented">Rented</option>
            <option value="maintenance">Maintenance</option>
            <option value="sold">Sold</option>
            <option value="old">Old</option>
          </select>
        </div>

        {/* Condition Text Input */}
        <div>
          <label className="block text-sm font-medium text-gray-700">Condition Notes</label>
          <textarea
            value={condition}
            onChange={(e) => setCondition(e.target.value)}
            placeholder="Add any notes about the scooter's condition or situation"
            className="w-full border border-gray-300 rounded-lg py-2 px-3 text-gray-800"
          />
        </div>

        {/* Submit Button */}
        <div>
          <button
            type="submit"
            className="w-full bg-indigo-600 text-white py-2 px-4 rounded-lg hover:bg-indigo-700 transition duration-200"
          >
            Add Scooter
          </button>
        </div>
      </form>
    </div>
    </div>
  );
};

export default NewScooter;
