import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import config from '../config';

const ScooterList = () => {
  const [scooters, setScooters] = useState([]);
  const [searchPlateNumber, setSearchPlateNumber] = useState('');
  const [searchStatus, setSearchStatus] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [editScooter, setEditScooter] = useState(null);

  const token = localStorage.getItem('token');

  // Fetch scooters with useCallback
  const fetchScooters = useCallback(async (page = 1, plateNumber = '', status = '') => {
    try {
      const response = await axios.get(`${config.apiBaseUrl}/api/scooters/getScooter`, {
        params: { page, plateNumber, status },
        headers: {
          'Authorization': `Bearer ${token}`, // Pass the token in the headers
        },
      });
      setScooters(response.data.scooters);
      setTotalPages(response.data.totalPages);
    } catch (error) {
      console.error("Error fetching scooters:", error);
    }
  }, [token]);

  useEffect(() => {
    fetchScooters(currentPage);
  }, [currentPage, fetchScooters]);

  // Handle search by plate number
  const handleSearch = () => {
    setCurrentPage(1); // Reset to first page on search
    fetchScooters(1, searchPlateNumber, searchStatus);
  };

  // Handle pagination
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
    fetchScooters(newPage, searchPlateNumber, searchStatus);
  };

  // Handle edit button click
  const handleEditClick = (scooter) => {
    setEditScooter(scooter); // Open dialog with selected scooter's data
  };

  // Handle save after editing
  const handleSave = async () => {
    try {
      await axios.put(`${config.apiBaseUrl}/api/scooters/updateScooter/${editScooter.scooter_id}`, {
        plate_number: editScooter.plate_number,
        status: editScooter.status,
        condition: editScooter.condition || '', // Allow condition to be optional
      },
      {
      headers: {
        'Authorization': `Bearer ${token}`, // Pass the token in the headers
      },
    });
      Swal.fire('Success', 'Scooter details updated!', 'success');
      setEditScooter(null); // Close dialog
      fetchScooters(currentPage); // Refresh scooter list
    } catch (error) {
      console.error("Error updating scooter:", error);
      Swal.fire('Error', 'Failed to update scooter', 'error');
    }
  };

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">View/Edit/Delete Scooters</h1>

      {/* Search Field */}
      <div className="mb-4 flex">
        <input
          type="text"
          placeholder="Enter Plate Number"
          value={searchPlateNumber}
          onChange={(e) => setSearchPlateNumber(e.target.value)}
          className="border rounded-l px-3 py-2 w-1/2"
        />
        {/* Status Filter Dropdown */}
        <select
          value={searchStatus}
          onChange={(e) => setSearchStatus(e.target.value)}
          className="border px-3 py-2 mx-2 w-1/4"
        >
          <option value="">Select Status</option>
          <option value="available">Available</option>
          <option value="rented">Rented</option>
          <option value="maintenance">Maintenance</option>
          <option value="sold">Sold</option>
          <option value="old">Old</option>
        </select>
        <button
          onClick={handleSearch}
          className="bg-blue-500 text-white rounded-r px-4 py-2 hover:bg-blue-600"
        >
          Search
        </button>
      </div>


      {/* Scooter Table */}
      <table className="w-full bg-white rounded-lg shadow">
        <thead>
          <tr>
            <th className="py-2 px-4 border">Scooter ID</th>
            <th className="py-2 px-4 border">Plate Number</th>
            <th className="py-2 px-4 border">Status</th>
            <th className="py-2 px-4 border">Condition</th>
            <th className="py-2 px-4 border">Actions</th>
          </tr>
        </thead>
        <tbody>
          {scooters.map((scooter) => (
            <tr key={scooter.scooter_id}>
              <td className="py-2 px-4 border">{scooter.scooter_id}</td>
              <td className="py-2 px-4 border">{scooter.plate_number}</td>
              <td className="py-2 px-4 border">{scooter.status}</td>
              <td className="py-2 px-4 border">{scooter.condition}</td>
              <td className="py-2 px-4 border">
                <button
                  onClick={() => handleEditClick(scooter)}
                  className="bg-yellow-500 text-white px-3 py-1 rounded hover:bg-yellow-600"
                >
                  Edit
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Pagination */}
      <div className="flex justify-center my-4">
        <button
          disabled={currentPage === 1}
          onClick={() => handlePageChange(currentPage - 1)}
          className="px-4 py-2 mx-1 rounded bg-gray-300 hover:bg-gray-400 disabled:bg-gray-200"
        >
          Previous
        </button>
        <span className="px-4 py-2">{currentPage} of {totalPages}</span>
        <button
          disabled={currentPage === totalPages}
          onClick={() => handlePageChange(currentPage + 1)}
          className="px-4 py-2 mx-1 rounded bg-gray-300 hover:bg-gray-400 disabled:bg-gray-200"
        >
          Next
        </button>
      </div>

      {/* Edit Dialog */}
      {editScooter && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
          <div className="bg-white p-4 rounded-lg shadow-lg">
            <h2 className="text-xl mb-4">Edit Scooter</h2>
            <div className="mb-2">
              <label>Plate Number</label>
              <input
                type="text"
                value={editScooter.plate_number}
                onChange={(e) => setEditScooter({ ...editScooter, plate_number: e.target.value })}
                className="border px-2 py-1 w-full"
              />
            </div>
            <div className="mb-2">
              <label>Status</label>
              <select
                value={editScooter.status}
                onChange={(e) => setEditScooter({ ...editScooter, status: e.target.value })}
                className="border px-2 py-1 w-full"
              >
                <option value="available">Available</option>
            <option value="rented">Rented</option>
            <option value="maintenance">Maintenance</option>
            <option value="sold">Sold</option>
            <option value="old">Old</option>
                {/* Add more status options if necessary */}
              </select>
            </div>
            <div className="mb-2">
              <label>Condition (optional)</label>
              <input
                type="text"
                value={editScooter.condition || ''}
                onChange={(e) => setEditScooter({ ...editScooter, condition: e.target.value })}
                className="border px-2 py-1 w-full"
              />
            </div>
            <div className="flex justify-end mt-4">
              <button
                onClick={() => setEditScooter(null)}
                className="bg-gray-400 text-white px-4 py-2 rounded mr-2 hover:bg-gray-500"
              >
                Cancel
              </button>
              <button
                onClick={handleSave}
                className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
              >
                Save
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ScooterList;
