import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import axios from 'axios';  // Make sure to install axios if you haven't already
import { useTranslation } from 'react-i18next';
import config from '../config';

function Dashboard() {
    const { t } = useTranslation();
    const navigate = useNavigate();

    // States to store counts
    const [activeRentedScooters, setActiveRentedScooters] = useState(0);
    const [availableScooters, setAvailableScooters] = useState(0);
    const [brokenScooters, setBrokenScooters] = useState(0);
    const [registeredCustomers, setRegisteredCustomers] = useState(0);
    const [oldSoldScooters, setOldSoldScooters] = useState(0);
    const [loading, setLoading] = useState(true);

    // Fetch the data from the backend API
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(
                  `${config.apiBaseUrl}/api/dashboard/getCount`,
                  {
                    headers: {
                      'Content-Type': 'application/json',
                      Authorization: `Bearer ${localStorage.getItem("token")}`,
                    }
                  }
                  ); // Adjust the URL if necessary
                const data = response.data;

                setActiveRentedScooters(data.active_rented_scooters);
                setAvailableScooters(data.available_scooters);
                setBrokenScooters(data.broken_scooters);
                setRegisteredCustomers(data.registered_customers);
                setOldSoldScooters(data.old_sold_scooters);
            } catch (error) {
              console.error('Error fetching dashboard data:', error);
              if (error.response && error.response.status === 401) {
                // Unauthorized, remove token and redirect to home
                localStorage.removeItem("token");
              }
            } finally {
              setLoading(false);
            }
        };
        fetchData();
    }, [navigate]);

    if (loading) {
        return <p>{t('Loading...')}</p>;  // Display loading state
    }

    return (
        <div>
            <h1 className="text-3xl font-bold mb-6">{t('Dashboard')}</h1>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6">
                <div className="bg-white p-6 rounded-lg shadow-md hover:shadow-lg transition duration-300">
                    <h3 className="text-xl font-semibold mb-2">{t('Active Rented Scooters')}</h3>
                    <p className="text-4xl font-bold text-indigo-600">{activeRentedScooters}</p>
                </div>
                <div className="bg-white p-6 rounded-lg shadow-md hover:shadow-lg transition duration-300">
                    <h3 className="text-xl font-semibold mb-2">{t('Available Scooters')}</h3>
                    <p className="text-4xl font-bold text-green-500">{availableScooters}</p>
                </div>
                <div className="bg-white p-6 rounded-lg shadow-md hover:shadow-lg transition duration-300">
                    <h3 className="text-xl font-semibold mb-2">{t('Broken Scooters')}</h3>
                    <p className="text-4xl font-bold text-red-500">{brokenScooters}</p>
                </div>
                <div className="bg-white p-6 rounded-lg shadow-md hover:shadow-lg transition duration-300">
                    <h3 className="text-xl font-semibold mb-2">{t('Registered Customers')}</h3>
                    <p className="text-4xl font-bold text-indigo-600">{registeredCustomers}</p>
                </div>
                <div className="bg-white p-6 rounded-lg shadow-md hover:shadow-lg transition duration-300">
                    <h3 className="text-xl font-semibold mb-2">{t('Old/Sold Scooters')}</h3>
                    <p className="text-4xl font-bold text-yellow-600">{oldSoldScooters}</p>
                </div>
            </div>
        </div>
    );
}

export default Dashboard;
