import React, { useState } from 'react';
import axios from 'axios';
// import Swal from 'sweetalert2';
import config from '../config';

const ReturnRental = () => {
  const [plateNumber, setPlateNumber] = useState('');
  const [loading, setLoading] = useState(false);
  const [startTime, setStartTime] = useState(null);
  const [totalAmount, setTotalAmount] = useState(null);
  const [scooterDetails, setScooterDetails] = useState([]);
  const [customerDetails, setCustomerDetails] = useState(null);
  const token = localStorage.getItem('token');
  const handleSearch = async () => {
    setLoading(true);
    try {
      const response = await axios.post(`${config.apiBaseUrl}/api/orders/getOrder`,
       { plate_number: plateNumber },
       {
        headers: {
          'Authorization': `Bearer ${token}`,
        }
    }
     );
    //  console.log(response.data.start_date);
     setStartTime(response.data.start_date);
     setTotalAmount(response.data.total_Amount);
      setScooterDetails(response.data.scooters);
      if(response)
      {
        const customer = await axios.post(`${config.apiBaseUrl}/api/orders/getCustomerOrder`,
        { orderId: response.data.orderId },
        {
         headers: {
           'Authorization': `Bearer ${token}`,
         }
     }
      );
      setCustomerDetails(customer.data.customer);
        
      }
    } catch (error) {
      console.error('Error fetching details:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleUpdate = async () => {
    // Logic for updating the order details with modified status and conditions
  };

  const handleReset = () => {
    setPlateNumber('');
    setScooterDetails([]);
    setCustomerDetails(null);
  };

  return (
    <div className="return-rental max-w-3xl mx-auto p-6 bg-white rounded-lg shadow-lg mt-10">
      <h2 className="text-2xl font-bold mb-6 text-center text-blue-700">Return Rented Scooter</h2>
      <div className="search-section flex gap-4 mb-8">
        <input
          type="text"
          placeholder="Enter Rented Scooter No."
          className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400"
          value={plateNumber}
          onChange={(e) => setPlateNumber(e.target.value)}
        />
        <button
          onClick={handleSearch}
          className="px-6 py-3 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition"
        >
          Search
        </button>
      </div>

      {loading && <div className="spinner text-center text-lg">Loading...</div>}

      {!loading && scooterDetails.length > 0 && (
        <div>
          <h3 className="text-xl font-semibold mb-4 text-gray-700">Scooter Details</h3>
          <table className="min-w-full bg-white border border-gray-200 rounded-lg">
            <thead>
              <tr>
                <th className="py-3 px-4 border-b text-left text-gray-600">Scooter Plate Number</th>
                <th className="py-3 px-4 border-b text-left text-gray-600">Status</th>
                <th className="py-3 px-4 border-b text-left text-gray-600">Condition</th>
              </tr>
            </thead>
            <tbody>
              {scooterDetails.map((scooter) => (
                <tr key={scooter.scooter_id} className="border-t hover:bg-gray-50">
                  <td className="py-3 px-4">{scooter.plate_number}</td>
                  <td className="py-3 px-4">
                    <select
                      value={scooter.status}
                      onChange={(e) =>
                        setScooterDetails((prevDetails) =>
                          prevDetails.map((item) =>
                            item.scooter_id === scooter.scooter_id
                              ? { ...item, status: e.target.value }
                              : item
                          )
                        )
                      }
                      className="p-2 border border-gray-300 rounded"
                    >
                      <option value="rented">Rented</option>
                      <option value="available">Available</option>
                      <option value="old">Old</option>
                      <option value="broken">Broken</option>
                    </select>
                  </td>
                  <td className="py-3 px-4">
                    <input
                      type="text"
                      disabled={scooter.status !== 'available'}
                      value={scooter.condition || ''}
                      onChange={(e) =>
                        setScooterDetails((prevDetails) =>
                          prevDetails.map((item) =>
                            item.scooter_id === scooter.scooter_id
                              ? { ...item, condition: e.target.value }
                              : item
                          )
                        )
                      }
                      className="p-2 border border-gray-300 rounded w-full focus:outline-none focus:ring-2 focus:ring-blue-400 disabled:bg-gray-100"
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          <h3 className="text-xl font-semibold mt-8 mb-4 text-gray-700">Customer Details</h3>
          <div className="customer-details bg-gray-50 p-4 rounded-lg shadow-md space-y-3">
            <p><strong>Customer Name:</strong> {customerDetails?.name}</p>
            <p><strong>Phone Number:</strong> {customerDetails?.phoneNumber}</p>
            <p><strong>Address:</strong> {customerDetails?.homeAddress}</p>
            <p><strong>Government ID:</strong> 
              <img src={customerDetails?.govtIdPic} alt="Govt ID" width="100" className="mt-2" />
            </p>
            <p><strong>Date of Transaction: </strong>{new Date(startTime).toLocaleString()}</p>
          </div>


          <h3 className="text-xl font-semibold mt-8 mb-4 text-gray-700">Payment Details</h3>
<div className="payment-details mb-8 flex items-center">
  <label className="text-gray-700 font-semibold mr-4">Total Amount:</label>
  <input
    type="number"
    value={totalAmount}
    onChange={(e) => setTotalAmount(e.target.value)}
    className="p-2 border border-gray-300 rounded-lg w-32 focus:outline-none focus:ring-2 focus:ring-blue-400"
  />
</div>


          <div className="actions mt-8 flex gap-4">
            <button
              onClick={handleUpdate}
              className="px-6 py-3 bg-green-500 text-white rounded-lg hover:bg-green-600 transition"
            >
              Update Order
            </button>
            <button
              onClick={handleReset}
              className="px-6 py-3 bg-gray-300 text-gray-700 rounded-lg hover:bg-gray-400 transition"
            >
              Reset
            </button>
          </div>
        </div>
      )}
    </div>
  );

};

export default ReturnRental;
